<template>
  <div class="mx-8" no-gutters v-if="listadoLotes.length > 0">
    <v-row>
      <v-col cols="12" sm="12" md="4">
        <v-text-field
          v-model="ofertaInfo.calificacion_oferta"
          outlined
          v-mask="'##'"
          label="Calificación de oferta técnica"
          :error-messages="calificacionErrors"
          @input="$v.ofertaInfo.calificacion_oferta.$touch()"
        />
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <p>Al ingresar una calificación y seleccionar algún lote se establece que el proveedor puede seguir participando en el proceso.</p>
      </v-col>
    </v-row>

    <span class="text-sm-h5" v-text="'Seleccione los lotes a los que aplica'" />
    <v-expansion-panels focusable accordion flat>
      <v-expansion-panel
        v-for="(lote, i) in listadoLotes"
        :key="i"
        class="panel"
      >
        <v-expansion-panel-header class="panel">
          <div class="d-flex flex-row align-center">
            <span class="mt-2 mr-6" v-text="lote.lote?.nombre" />
            <v-btn
              :dark="!lote.estado"
              :outlined="lote.estado"
              class="text-no-style btn-extra"
              color="secondary"
              @click="lote.estado = !lote.estado"
            >
              {{ lote.estado ? "Eliminar asignación de lote" : "Asignar lote" }}
            </v-btn>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pa-5">
          <data-table-component
            :headers="headersObs"
            :items="lote.obs"
            mobile_breakpoint="725"
            :tiene_paginacion="false"
            :custom_item_per_page="lote.obs.length"
          >
          </data-table-component>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-row no-gutters class="my-8">
      <v-btn
        text
        class="mt-5 secondary--text text-no-style mx-3"
        v-text="'Volver'"
        @click="$router.back()"
      />
      <v-btn
        :disabled="
          !ofertaInfo.puede_participar && haveRole('ROLE_OFICIAL_CUMPLIMIENTO')
        "
        class="mt-5 text-no-style mx-3 white--text"
        color="secondary"
        v-text="'Guardar'"
        @click="saveOfertaT"
      />
    </v-row>
  </div>
</template>
<script>
import DataTableComponent from "../../../components/DataTableComponent.vue";
import { mapState } from "vuex";
import { maxValue, minValue, required } from "vuelidate/lib/validators";

export default {
  name: "lotesOfertaTecnicaComponent",
  components: {
    DataTableComponent,
  },
  data: () => ({
    headersObs: [
      {
        text: "OBS",
        align: "start",
        value: "nombre",
      },

      {
        text: "Especificación",
        value: "especificacion",
      },
      {
        text: "Unidad",
        value: "unidad",
      },
    ],
  }),
  validations: {
    ofertaInfo: {
      calificacion_oferta: { required, maxValue: maxValue(10), minValue: minValue(1) },
    },
  },
  computed: {
    ...mapState("peoStore", ["listadoLotes", "ofertaInfo"]),
    calificacionErrors() {
      const errors = [];
      if (!this.$v.ofertaInfo.calificacion_oferta.$dirty) return errors;
      !this.$v.ofertaInfo.calificacion_oferta.required &&
        errors.push("Campo requerido");
      !this.$v.ofertaInfo.calificacion_oferta.minValue &&
        errors.push("La calificación debe ser mayor a cero");
      !this.$v.ofertaInfo.calificacion_oferta.maxValue &&
        errors.push("La calificación debe ser entre los números 1 y 10");
      return errors;
    },
  },
  methods: {
    async saveOfertaT() {
      this.$v.ofertaInfo.$touch();

      if (!this.$v.ofertaInfo.$invalid) {

        let body = {
          lotes: this.listadoLotes
            .filter((lote) => lote.estado)
            .map((col) => col.id_lote),
          calificacion: this.ofertaInfo.calificacion_oferta,
          puede_participar: true,
        };

        const { status } = await this.services.PeoServices.postOfertaTecnica(
          this.$route.params.id_proceso,
          this.ofertaInfo.id,
          body
        )

        if (status == 200) {
          this.temporalAlert({
            show: true,
            type: "success",
            message: "Se ha adjudicado al ganador correctamente",
          });

          this.$router.back();
        }

      }
    },
  },
};
</script>
