<template>
  <section class="px-md-8">
    <!-- Breadcrumbs / migas de pan -->
    <v-row>
      <v-col cols="12" v-if="$route.query.from != 'adjudicacion'">
        <v-breadcrumbs large :items="breadcrumbsItems"></v-breadcrumbs>
      </v-col>
    </v-row>
    <v-row :class="`${$route.query.from == 'adjudicacion' ? 'mt-12' : ''} mx-4`" align="center">
      <v-col cols="12" sm="12" md="4">
        <v-select
            outlined
            hide-details
            label="Tipo de oferta"
            placeholder="Seleccione"
            :items="ctlTipoOferta"
            item-text="nombre"
            v-model="idTipoOferta"
            item-value="id"
            :readonly="disabledProvider || disableTipo"
            @change="getGanadores"
        />
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <v-select
            outlined
            label="Proveedores"
            placeholder="Seleccione"
            :items="proveedoresHabilitados"
            item-text="nombre"
            v-model="proveedor_oferta"
            return-object
            hide-details
            :disabled="!idTipoOferta"
            item-value="id"
            @change="getOfertas"
        />
      </v-col>
      <v-col v-if="proveedor_oferta" cols="12" sm="12" md="4">
        <v-btn dark outlined color="green" @click="downloadDocumentOffer"
        >Descargar oferta
        </v-btn
        >
      </v-col>
    </v-row>
    <template v-if="idTipoOferta == 2 || [1, 2].includes(this.idTipoOferta) && this.$route.query.tipo == 'ofertasCombinadas' || this.$route.query.tipo == 'editarOfertaAdjudicado'">
      <lotesOfertaFinancieraComponent :proveedorOferta="proveedor_oferta"/>
    </template>
    <template v-else>
      <lotesOfertaTecnicaComponent/>
    </template>
  </section>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import lotesOfertaFinancieraComponent from "./components/lotesOfertaFinancieraComponent.vue";
import lotesOfertaTecnicaComponent from "./components/lotesOfertaTecnicaComponent.vue";

export default {
  components: {
    lotesOfertaFinancieraComponent,
    lotesOfertaTecnicaComponent,
  },
  data: () => ({
    lotesList: [],

    ctlProveedores: [],
    proveedor_oferta: null,
    disabledProvider: false,
    disableTipo: false,
    ctlTipoOferta: [
      {
        id: 1,
        nombre: "Oferta técnica",
      },
      {
        id: 2,
        nombre: "Oferta económica",
      },
    ],
    idTipoOferta: null,
  }),
  methods: {
    ...mapMutations("procesoCompraDoc", ["setInfoProceso", "setParticipando"]),
    ...mapMutations("peoStore", [
      "setOfertaTecnica",
      "setOfertaFinanciera",
      "setOfertaFinancieraProcesosLibres",
      "clearListadoLotes",
    ]),
    close() {
      this.showDialog = false;
    },

    getOfertas() {
      if ([1, 2].includes(this.idTipoOferta) && this.$route.query.tipo == "ofertasCombinadas" || this.$route.query.tipo == "editarOfertaAdjudicado") {
        this.setOfertaFinancieraProcesosLibres({
            proveedor: this.proveedor_oferta,
            vm: this
          });
      }
      else if (this.idTipoOferta == 1) {
          this.setOfertaTecnica({
            proveedor: this.proveedor_oferta,
            vm: this
          });
      } else if (this.idTipoOferta == 2) {
        this.setOfertaFinanciera({
          proveedor: this.proveedor_oferta,
          vm: this,
        });
      }
    },
    async getGanadores() {
      if (this.idTipoOferta == 1 && this.$route.query.tipo == "ofertasCombinadas") {
        this.getOfertaCombinadaOfertaTecnica();
      }
      else if (this.idTipoOferta == 2 && this.$route.query.tipo == "ofertasCombinadas") {
        this.getOfertaCombinadaOfertaEconomica();
      }
      else if (this.idTipoOferta == 1) {
        this.getGanadoresTecnicos();
      } else {
        this.getGanadoresFinancieros();
      }
    },
    async getGanadoresFinancieros() {
      const query = {}
      if (this.$route.query.subasta === 'true') {
        query.subasta = true
      }

      const {status, data} =
          await this.services.PeoServices.getGanadoresProcesos(
              this.$route.params.id_proceso,
              '',
              query
          );
      if (status == 200) {
        this.ctlProveedores = data.map((col) => ({
          ...col,
          id: col.id,
          nombre: col.ParticipacionProveedor?.Proveedor?.nombre,
        }));
      }
    },

    // Obtener las ofertas economicas para adjudicar ofertas combinadas provenientes de consultoria
    async getOfertaCombinadaOfertaTecnica() {
      const query = {}
      const {status, data} =
          await this.services.PeoServices.getOfertasCombinadasOfertasTecnicasProveedoresCtl(
              this.$route.params.id_proceso,
              query
          );

      if (status == 200) {
        this.ctlProveedores = data.map((col) => ({
          ...col,
          id: col.id,
          nombre: col.ParticipacionProveedor?.Proveedor?.nombre,
        }));
      }
    },
    // Obtener las ofertas economicas para adjudicar ofertas combinadas provenientes de consultoria
    async getOfertaCombinadaOfertaEconomica() {
      const query = {}
      const {status, data} =
          await this.services.PeoServices.getOfertasCombinadasOfertasEconomicasProveedoresCtl(
              this.$route.params.id_proceso,
              query
          );

      if (status == 200) {
        this.ctlProveedores = data.map((col) => ({
          ...col,
          id: col.id,
          nombre: col.ParticipacionProveedor?.Proveedor?.nombre,
        }));
      }
    },
    async getGanadoresTecnicos() {
      const {status, data} =
          await this.services.PeoServices.getGanadoresOfertaTecnica(
              this.$route.params.id_proceso
          );
      if (status == 200) {
        this.ctlProveedores = data.map((col) => ({
          ...col,
          id: col.id,
          nombre: col?.Proveedor?.nombre,
        }));
      }
    },
    async getGanadorI() {
      const {status, data} =
          await this.services.PeoServices.getGanadoresProcesos(
              this.$route.params.id_proceso,
              this.$route.params.id_oferta,
              {ganador: true}
          );
      if (status == 200) {
        this.ctlProveedores = data.map((col) => ({
          ...col,
          id: col.id,
          nombre: col.ParticipacionProveedor?.Proveedor?.nombre,
        }));
        this.proveedor_oferta = this.ctlProveedores[0];
        this.idTipoOferta = this.proveedor_oferta.id_tipo_oferta;
        this.getOfertas();
        this.disabledProvider = true;
      }
    },
    async getAdjudicacionTipoOferta() {
      const {status, data} = await this.services.PeoServices.getAdjudicacionOferta(this.$route.params.id_proceso, this.$route.params.id_oferta, {ganador: true});
      if (status == 200) {
        this.ctlProveedores = data.map((col) => ({
          ...col,
          id: col.id,
          nombre: col.ParticipacionProveedor?.Proveedor?.nombre,
        }));
        this.proveedor_oferta = this.ctlProveedores[0];
        this.idTipoOferta = this.proveedor_oferta.id_tipo_oferta;
        this.getOfertas();
        this.disabledProvider = true;
      }
    },
    async getGanadorT() {
      const {status, data} =
          await this.services.PeoServices.getGanadorOfertaTecnica(
              this.$route.params.id_proceso,
              this.$route.params.id_oferta
          );
      if (status == 200) {
        this.ctlProveedores = data.map((col) => ({
          ...col,
          id: col.id,
          nombre: col?.Proveedor?.nombre,
        }));
        this.proveedor_oferta = this.ctlProveedores[0];
        this.idTipoOferta = this.proveedor_oferta?.ofertas[0]?.id_tipo_oferta;
        this.getOfertas();
        this.disabledProvider = true;
      }
    },
    async getProceso() {
      const response = await this.services.PacProcesos.getProceso(
          this.$route.params.id_proceso
      );

      if (response.status === 200) {
        this.setInfoProceso(response.data);
      }
    },
    async downloadDocumentOffer() {

      let ruta = '';

      if (this.proveedor_oferta?.ofertas) {
        ruta = this.proveedor_oferta?.ofertas[0]?.documento_oferta;
      } else {
        ruta = this.proveedor_oferta?.documento_oferta
      }


      const response =
          await this.services.PacProcesos.descargarDocumentoProceso({
            ruta: ruta,
            disk: "procesos",
          }).catch(() => {

          });
      if (!response) {
        this.temporalAlert({
          show: true,
          message: "Ocurrió un error al descargar el documento",
          type: "error",
        });

        return;
      }

      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });

      const file = new File([blob], "oferta", {
        type: response.headers["content-type"],
      });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(file);
      link.download = "";
      link.click();

    },
  },
  computed: {
    ...mapState("procesoCompraDoc", ["procesoData", "participando"]),
    proveedoresHabilitados(){
      return this.ctlProveedores.filter((proveedor)=>
        !proveedor?.ParticipacionProveedor?.Proveedor?.Usuario?.is_disabled && 
        !proveedor?.Proveedor?.Usuario?.is_disabled
      );
    }, 
    breadcrumbsItems() {
      return [
        {
          text: "Evaluaciones PEO",
          disabled: false,
          href: "#",
        },
        {
          text: this.procesoData?.codigo_proceso,
          disabled: false,
          href: "#",
        },
      ];
    },
  },
  created() {
    this.getProceso();
    if (this.$route.params.id_oferta) {
      if (this.$route.query.tipo == "tecnica") {
        this.getGanadorT();
      } else if (this.$route.query.tipo == "financiera") {
        this.getGanadorI();
      } else if (this.$route.query.tipo == "editarOfertaAdjudicado") {
        this.getAdjudicacionTipoOferta();
      }
    }

    if (!this.$route.params.id_oferta && this.$route.query.tipo) {
      switch (this.$route.query.tipo) {
        case "tecnica":
          this.idTipoOferta = 1;
          this.disableTipo = true;
          this.getGanadoresTecnicos();
          break;

        case "financiera":
          this.idTipoOferta = 2;
          this.disableTipo = true;
          this.getGanadoresFinancieros();
          break;
        
          case "ofertasCombinadas":
          this.disableTipo = false;
          this.getOfertaCombinadaOfertaEconomica();
          this.getOfertaCombinadaOfertaTecnica();
          break;

        default:
          this.idTipoOferta = null;
          this.disableTipo = false;
          break;
      }
    }
  },
  beforeDestroy() {
    this.clearListadoLotes();
  },
};
</script>
<style lang="scss" scoped>
:deep(.v-input__control) #chk-input {
  width: auto;
  max-width: min-content;
}
</style>
